.place-select__map-marker {
    border: 1px solid rgb(0, 72, 206);
    border-width: 3px 1px;
    width: 20px !important;
    height: 20px !important;
    margin-left: -10px !important;
    margin-top: -10px !important;
    background: linear-gradient(to top, #d4d4d48a, #ffffffaa);
    border-radius: 10px;
}

.validated-marker {
    background: linear-gradient(to top, rgba(238, 255, 0, 0.3), rgba(238, 255, 0, 0.3));
}

.selected-marker {
    background: linear-gradient(to top, #509252aa, #509252aa);
}