.place-select__coords_input {
    margin-bottom: 10px;
}

.place-select__buttons button {
    margin-right: 10px;
}

.place-select__buttons button:last-child {
    margin-right: 0;
}

.place-select__search_input {
    width: 300px;
}