.trip-selector-transfer-orders__title,
.trip-selector-transfer-orders__info,
.trip-selector-transfer-orders__control {
    width: 100%;
    flex-basis: content;
    margin-top: 19px;
}

.trip-selector-transfer-orders__title {
    margin-top: 0;
    font-size: 16px;
}

.trip-selector-transfer-orders__close-button {
    padding-top: inherit;
    padding-right: inherit;
    position: absolute;
    top: 0;
    right: 0;
}

.trip-selector-transfer-orders__control_dark-item {
    background: linear-gradient(90deg, #ffffff 0%, #c9c9c9 100%);
}