.container {
    min-width: 300px;
    flex: 40% 0;
}

.item {
    padding-bottom: 18px;
    margin-bottom: 20px;
    border-bottom: 1px solid;
}

.daysOfWeekSelectorWrap {
    margin-bottom: 10px;
    display: flex;
}

.daysOfWeekSelector {
    flex-grow: 1;
}

.delete {
    color: red;
    cursor: pointer;
    flex-grow: 0;
    margin-left: auto;
}

.delete svg {
    width: 20px;
    height: 20px;
}

.intervalSelector {
    display: flex;
}

.error {
    color: red;
}

.saveContainer {
    display: flex;
    gap: 10px;
}

.addContainer {
    display: flex;
    margin-bottom: 10px;
}

.saveButton {
    margin-left: auto;
    width: 100px;
}

.addButton {
    margin-left: auto;
    width: 100px;
}

.spin {
    width: 100%;
    margin-top: 100px;
}

.addInterval {
    cursor: pointer;
}
