.route-info {
}

.route-info__item {
    position: relative;
    display: flex;
    flex-direction: row;
}

.route-info__item:not(:last-child) {
    margin-bottom: 1px;
}

.route-info__order-status-wrap {
    margin-bottom: 4px;
}

.route-info__order-status {    
    vertical-align: bottom;
}

.route-info__order-status-wrap .order-transferred-to {
    max-width: 145px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
}

.route-info__item_selected {
    background-color: #e7f9ff;
}

.route-info__item_clickable {
    cursor: pointer;
}

.route-info__item_clickable:hover {
    background-color: #e7f9ff;
}

.route-info__item-line {
    flex: 0 0 auto;
    width: 32px;
    position: relative;
    margin: 8px 24px 8px 8px;
}

.route-info__item-line:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1px;
    height: 100%;
    border-left: 1px solid #1890ff;
}

.route-info__item-line_nolegs {
    margin-top: 20px;
    margin-bottom: 20px;
}

.route-info__item-line_no-distance {
    margin-top: -9px;
}

.route-info__item-line_no-distance .route-info__item-line-dot {
    margin-top: 3px;
}

.route-info__item.route-info__item_first .route-info__item-line:before {
    top: 50%;
    height: 50%;
}

.route-info__item.route-info__item_last .route-info__item-line:before {
    height: 50%;
}

.route-info__item-line-dot {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 13px;
    height: 13px;
    margin: -6px 0 0 -6px;
    border: 1px solid transparent;
    border-radius: 50%;
}

.route-info__item-line-dot-icon {
    position: absolute;
}

.route-info__item-line-dot-icon_done {
    top: 3px;
    left: 2px;
    width: 7px;
    height: 5px;
    color: #fff;
}

.route-info__item-line-dot-icon_failed {
    top: 2px;
    left: 3px;
    width: 6px;
    height: 7px;
    color: #fff;
}

.route-info__item-line-dot:before {
    content: '';
    display: block;
    width: 9px;
    height: 9px;
    margin: 1px 0 0 1px;
    background-color: #fff;
    border: 1px solid #1890ff;
    border-radius: 50%;
}

.route-info__item_moved .route-info__item-line:before {
    border-color: #b5b5b5;
}

.route-info__item_moved .route-info__item-line-dot {
    display: none;
}

.route-info__item_done .route-info__item-line:before,
.route-info__item_done .route-info__item-line-dot:before {
    border-color: #4acc56;
}

.route-info__item_done .route-info__item-line-dot:before {
    background-color: #4acc56;
}

.route-info__item_failed .route-info__item-line:before,
.route-info__item_failed .route-info__item-line-dot:before {
    border-color: #ff5555;
}

.route-info__item_failed .route-info__item-line-dot:before {
    background-color: #ff5555;
}

.route-info__item_rescheduled .route-info__item-line:before,
.route-info__item_rescheduled .route-info__item-line-dot:before {
    border-color: #ff5555;
}

.route-info__item_rescheduled .route-info__item-line-dot:before {
    background-color: #ff5555;
}

.route-info__item_first-scheduled-order .route-info__item-line-dot {
    border-color: #1890ff;
}

.route-info__item_first-scheduled-order .route-info__item-line-dot:before {
    background-color: #1890ff;
}

.route-info__item-content,
.route-info__item-content-compose {
    flex: 1 1 auto;
    padding: 8px 8px 8px 0;
}

.route-info__item-content-compose {
    display: flex;
}

.route-info__item-content-compose .ant-checkbox-wrapper {
    align-self: center;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    color: inherit;
}

.route-info__item-content_text {
    flex: 1 1 auto;
}

.route-info__item:not(:last-child) .route-info__item-content {
    margin-bottom: -1px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}

.route-info__item:not(:last-child) .route-info__item-content-compose {
    margin-bottom: -1px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}

.route-info__item-distance {
    height: 18px;
    margin: -17px 0 0;
    font-size: 10px;
    text-align: center;
    color: #40a9ff;
    border: 1px solid transparent;
    border-radius: 4px;
    white-space: nowrap;
}

.route-info__item-distance_clickable {
    cursor: pointer;
}

.route-info__item-distance_clickable:hover,
.route-info__item-distance_selected {
    border: 1px solid #40a9ff;
    background-color: #fff;
}

.route-info__timings {
    float: left;
}

.route-info__rescheduled {
    color: #FF4D4F;
    float: right;
}

.route-info__rescheduled svg {
    margin: 3px 8px 0 0;
    float: left;
}

.route-info__client-name {
    color: grey;
}

.route-info__client-name, .route-info__address, .route-info__timings {
    line-height: 17px;
}