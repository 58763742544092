.district-strict-interval-list__page,
.district-strict-interval-list__page .ant-spin-nested-loading,
.district-strict-interval-list__page .ant-spin-container {
    height: 100%;
}

.district-strict-interval-list__container {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.district-strict-interval-list__editor {
    flex: 0 1 400px;
    overflow-y: auto;
    height: 100%;
    background: #fcfcfc;
}

.district-strict-interval-list__map-container {
    flex: 1 0 auto;
    height: 100%;
}

.district-strict-interval-list__map-container .leaflet-container {
    border-radius: 4px;
}