.driver-login-editor__name {
    margin-bottom: 5px;
}
.driver-login-editor .driver-login-error-message {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;

    color: #ff2d2d;

    visibility: hidden;
}

.driver-login-editor.driver-login-error .driver-login-error-message {
    visibility: visible;
}

.driver-login-editor.driver-login-error .ant-input {
    border-color: #ff2d2d;
}

.driver-login-editor__credentials {
    display: flex;
}

.driver-login-editor__login {
    flex: 4 1 0;
}

.driver-login-editor__passgen {
    margin-left: 15px;
}

.driver-login-editor__pass {
    margin-left: 5px;
    flex: 3 1 0;
}
