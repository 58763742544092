.point-marker {
    width: 12px !important;
    height: 12px !important;
    margin-left: -6px !important;
    margin-top: -6px !important;
}

.point-marker.with-content {
    width: 16px !important;
    height: 16px !important;
    margin-left: -8px !important;
    margin-top: -8px !important;
}

.point-marker.trace {
    width: 8px !important;
    height: 8px !important;
    margin-left: -4px !important;
    margin-top: -4px !important;
}

.point-marker.trace div {
    border-radius: 0;
}

.point-marker div {
    width: inherit;
    height: inherit;
    border: 2px solid #000;
    border-radius: 50%;
    background-color: white;
    text-align: center;
    line-height: 12px;
    font-size: 11px;
}