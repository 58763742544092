.row {
    padding-bottom: 5px;
}

.time {
    width: 85px;
}

.value {
    width: 65px;
}

.addButton {
    margin-top: 10px;
}