.order-place-picker {
    display: flex;
    flex-direction: column;
}

.order-place-picker__order-info {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

.product-operation-list {
    flex: 0 1 45%;
}

.product-operation-list:not(:first-child) {
    padding-left: 8px;
}

.product-operation-list:not(:last-child) {
    padding-right: 8px;
}

.product-operation-list__header {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
}

.product-operation-list__order-product {
    display: flex;
    justify-content: space-between;
    margin-top: 2px;
    line-height: 1.2em;
}

.product-operation-list__order-product__name {
    margin-right: 16px;
}

.product-operation-list__order-product__count {
}

.ant-btn.order-place-picker__close-button:hover,
.ant-btn.order-place-picker__close-button {
    cursor: pointer;
    border: none;
    width: max-content;
    color: #FF1818;
    background: none;
    position: absolute;
    padding: 0;
    right: 0px;
    top: -28px;
}

.order-place-picker .place-select__map {
    max-height: none;
}

.order-place-picker .place-select__topbar {
    margin: 8px 0;
}

.order-place-picker .place-select__topbar p {
    margin-bottom: 0.5em;
}