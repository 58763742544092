.new-plan__block {
    margin: 15px 0 0 0;
}

.new-plan__date-picker {
    display: flex;
    height: 32px;
}

.new-plan__modal-content {
    margin-top: 16px;
}

.new-plan__modal-content-buttons {
    margin-bottom: 10px;
}

.new-plan__date-picker-form {
    width: 125px;
}

.new-plan__time-picker-form {
    width: 85px;
}

.new-plan__date-picker > *:not(:last-child) {
    margin-right: 16px;
}

.new-plan__block .new-plan__date-picker > * {
    margin-bottom: 0;
}

.new-plan__filters {
    display: flex;
    margin-bottom: 15px;
}

.new-plan__filters > *:not(:last-child) {
    margin-right: 16px;
}

.new-plan__dash-character {
    margin: 0 8px;
}

.new-plan__dash-character:before {
    content: '\2013';
}

.new-plan__close-button {
    padding: 8px;
    cursor: pointer;
}

.new-plan__close-address-edit-button {
    position: absolute;
    top: -8px;
    right: -32px;
}

.new-plan__saving-warning-icon {
    margin: -8px -8px -8px 8px;
    padding: 8px;
    cursor: help;
}

.new-plan__saving-warning-icon svg {
    width: 13px;
    height: 11px;
}

.new-plan__order-info {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 16px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

.new-plan__order-info-column {
    flex: 0 1 45%;
}

.new-plan__order-info-column:not(:first-child) {
    padding-left: 8px;
}

.new-plan__order-info-column:not(:last-child) {
    padding-right: 8px;
}

.new-plan__order-info-column-header {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
}

.new-plan__order-info-product {
    display: flex;
    justify-content: space-between;
    margin-top: 2px;
}

.new-plan__order-info-product-name {
    margin-right: 16px;
}

.new-plan__depart-period-filter,
.new-plan__district-group-filter {
    padding-top: 5px;
}

.new-plan__depart-period-title,
.new-plan__district-group-title {
    padding: 2px 5px 3px 5px;
    margin-right: 5px;
    font-weight: 500;
    width: 65px;
    display: inline-block;
    background-color: #efefef;
    opacity: 0.8;
}

.new-plan__block .ant-select-multiple .ant-select-selection-item-remove {
    font-size: 1em;
}

.new-plan__block .place-select__geocode-select-block .ant-radio-wrapper {
    font-size: 13px;;
}

.new-plan__block .place-select__geocode-select-block .ant-radio-group {
    min-height: 40px;
}

.new-plan__filters-column {
    display: flex;
    flex-direction: column;
}

.new-plan__filters-column_title {
    margin: 0 5px 0 0;
}

.new-plan__filters-column_select {
    display: inline-block;
    margin-right: 16px;
}

.new-plan__district-group {
    margin-bottom: 10px;
}