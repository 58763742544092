@media print {

    .ant-layout-sider,
    .ant-layout-footer,
    .ant-tabs {
        display: none;
    }

    .itinerary-report__entity-list th {
        background: white;
        border: 1px lightgray solid;
    }

    h1.page__title {
        margin-bottom: 25px;
    }

    @page {
        margin: 12mm 7mm;
    }

    html {
        zoom: 80%;
    }
}

.itinerary-report {
    width: 100%;
    height: 100%;
}

.itinerary-report__entity-list th,
.itinerary-report__entity-list td {
    font-size: 13px;
    padding: 5px;
}

.itinerary-report__entity-list .entity-list__cell.column-clientName,
.itinerary-report__entity-list .entity-list__cell.column-address,
.itinerary-report__entity-list .entity-list__cell.column-comment {
    min-width: 200px;
}

.itinerary-report__entity-list .entity-list__cell.column-comment {
    min-width: 300px;
}

.itinerary-report h2 {
    font-size: 30px;
    font-weight: 600;
}

.itinerary-report__header {
    display: flex;
    align-items: stretch;
    margin-bottom: 20px;
}

.itinerary-report__tab {
    margin: 0 0 0 30px;
}

.itinerary-report__header>div {
    min-width: 340px;
    max-width: 50%;
    border: 1px lightgray solid;
    padding: 10px;
}

.itinerary-report__movements {
    margin-right: 20px;
}

.itinerary-report__header h3 {
    font-weight: 600;
    color: #666;
    font-size: 16px;
    margin: 0;
}

.itinerary-report__movements_product-item {
    display: flex;
    justify-content: space-between;
}

.itinerary-report__header p {
    font-size: 14px;
    margin-bottom: 7px;
}

.itinerary__order_not-done {
    color: #c5c5c5;
}

.itinerary__order_is-active {
    color: #69b34c;
}

.itinerary__order-payment {
    display: flex;
    justify-content: space-around;
}

.itinerary__order-payment_red {
    color: red;
}