.district-list__page,
.district-list__page .ant-spin-nested-loading,
.district-list__page .ant-spin-container {
    height: 100%;
}

.district-list__container {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.district-list__editor {
    position: relative;
    flex: 0 1 400px;
    height: 100%;
    background: #fcfcfc;
    min-width: 380px;
}

.district-list__content {
    height: calc(100% - 80px);
    margin-top: 30px;
    overflow-y: auto;
}

.district-list__city-district {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    border: 1px solid lightgray;
    padding: 5px 10px;
    cursor: pointer;
    background: #ececec;
}

.district-list__city-district_selected {
    box-shadow: 0 0 3px 3px yellow inset;
}

.district-list__city-district-name {
    flex: 1;
}

.district-list__city-district-buttons-group {
    margin-left: 10px;
}

.district-list__toggle-list {
    position: absolute;
    right: 10px;
}

.district-list__button_save {
    position: absolute;
    bottom: 0;
    right: 10px;
}

.district-list__map-container {
    flex: 1 0 auto;
    min-width: 500px;
    height: 100%;
}

.district-list__map-container .leaflet-container {
    border-radius: 4px;
}

.leaflet-container svg {
    width: auto;
    height: auto;
}

.district-list__space {
    height: 1rem;
}