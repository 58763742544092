.container {
    min-width: 300px;
    flex: 60% 0;
}

.calendarContainer {
    display: flex;
    gap: 20px;
}

.adjustments {
    flex-grow: 1;
}

.spin {
    width: 100%;
    margin-top: 100px;
}

.calendar {
    display: inline-flex;
    gap: 20px;
    flex-wrap: wrap;
}

.monthTitle {
    text-transform: capitalize;
    text-align: center;
}

.calendarWeek,
.calendarDOWLabels {
    display: flex;
    width: 224px;
}

.calendarFirstWeek {
    justify-content: flex-end;
}

.calendarDay {
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin: 1px;
    font-size: 15px;
    text-align: center;
    cursor: not-allowed;
    opacity: 0.2;
}

.calendarDOW {
    width: 30px;
    height: 24px;
    font-size: 12px;
    text-align: center;
}

.calendarDayEditable {
    cursor: pointer;
    opacity: 1;
}

.calendarDayEditable.calendarDayNoIntervals {
    color: red;
}

.isToday {
    color: #1890ff !important;
    font-weight: bold;
}

.titleContainer {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
}

.titleContainer h2 {
    margin-bottom: 0;
}

.hasAdjustments {
    background-color: #ffa50066;
    border-radius: 100%;
}

.error {
    color: red;
}
