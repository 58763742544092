.driver-list__autocomplete {
    width: 100%;
    margin-bottom: 10px;
}

.driver-list__container {
    padding: 0 9px 5px 9px;
}

.driver-list__remove-button {
    border: none;
    background: none;
    cursor: pointer;
    height: 20px;
    box-shadow: none;
    padding: 0;
}

.driver-list__item {
    font-size: 12px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    justify-content: space-between;
}

.driver-list__item svg {
    color: red;
}

.driver-list__item_inactive {
    color: #888888;
}