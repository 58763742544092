.title {
    font-size: 12px;
    line-height: 22px;
    font-weight: 700;
    margin-bottom: 14px;
}

.pagination {
    margin-bottom: 16px;
}

.gray {
    background: linear-gradient(90deg, #ffffff 0%, #e0e0e0 100%);
}

.selected {
    background: #e6f7ff;
}

.item {
    display: flex;
    align-items: center;
    height: 27px;
    border: 1px solid #e6f4ff;
    cursor: pointer;
}

.iconWrapper {
    flex: 0 0 30px;
    display: flex;
    align-items: center;
    padding: 0 30px 0 10px;
}

.icon {
    width: 20px;
}

.name {
    padding-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}