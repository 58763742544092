.order-info {
	position: relative;
	width: 352px;
	padding: 16px 16px 24px;
	background-color: #fff;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	overflow-y: auto;
	color: rgba(0, 0, 0, 0.8);
}

.order-info__close-button {
	position: absolute;
	top: 6px;
	right: 6px;
	padding: 8px;
	cursor: pointer;
	z-index: 10001;
}

.order-info__block:not(:last-child) {
    margin-bottom: 16px;
}

.order-info__executor-data {
	margin-left: 16px;
	margin-top: 25px;
}

.order-info__line {
    position: relative;
    margin: 0 0 8px;
    padding-left: 32px;
}

.order-info__line-icon {
    position: absolute;
    top: 0;
    left: 0;
	width: 24px;
	height: 24px;
}

.order-info__edit-icon {
	background: url('../../../../icons/edit.svg') no-repeat 0px;
	margin-left: 15px;
	transition: none;
}

.order-info__edit-icon:hover {
	background: url('../../../../icons/edit_hover.svg') no-repeat 0px;
}

.order-info__edit-icon:focus {
	background: url('../../../../icons/edit_pressed.svg') no-repeat 0px;
}

.order-info__line-text {
	font-size: 16px;
	line-height: 120%;
	white-space: pre-wrap;
}

.order-info__line-text-client {
	max-width: 270px;
}

.order-info__secondary-text {
	opacity: 0.45;
}

.order-info__block-subheader {
    margin-bottom: 10px;
    font: normal 500 14px/120% Roboto, sans-serif;
	color: #828282; 
}

.order-info__operation-block {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	position: relative;
	margin: 0 0 8px;
	padding-left: 32px;
}

.order-info__operation-block-icon {
	position: absolute;
	top: 3px;
	left: 0;
}

.order-info__operation-block-text {
	font-size: 16px;
	line-height: 120%;
	color: rgba(0, 0, 0, 0.8);
}

.order-info__operation-block > *:not(:last-child) {
	margin-right: 16px;
}

.change-executor__block-subheader,
.reschedule-order__block-subheader {
	font-size: 12px;
	line-height: 22px;
	font-weight: bold;
	margin-bottom: 14px;
}

.change-executor__executor-list {
	max-height: 841px;
	overflow: auto;
	overflow-x: hidden;
}

.change-executor__executor-list__person-card-icon {
	width: 20px;
	margin-top: 4px;
}

.change-executor__executor-list__executorName {
	max-width: 180px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.change-executor__block__change-executor-button {
	width: 100%;
	margin-top: 18px;
}

.reschedule-order_date-selector {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}

.reschedule-order_date-picker {
	width: 120px;
}

.reschedule-order_time-begin-picker,
.reschedule-order_time-end-picker {
	width: 80px;
}

.reschedule-order_reason {
	width: 100%;
	margin-top: 18px;
}

.reschedule-order_button {
	margin-top: 18px;
}

.change-executor__executor_gray {
	background: linear-gradient(90deg, #ffffff 0%, #e0e0e0 100%);
}