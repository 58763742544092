.trips-map__marker {
    width: 18px !important;
    height: 18px !important;
    margin-left: -9px !important;
    margin-top: -9px !important;
    background: #e2e2e2;
    border-radius: 9px;
}

.trips-map__marker div {
    border: 2px solid #80808000;
    border-radius: inherit;
    font-size: 10px;
    font-weight: 700;
    text-align: center;
}

.trip-on-map__path_default {
    stroke-width: 3;
}

.trip-on-map__path_bold {
    stroke-width: 7;  
}

.trips-map__micro-marker {
    width: 10px !important;
    height: 10px !important;
    margin-left: -11px !important;
    margin-top: -11px !important;
    background: #e2e2e2;
    border-radius: 5px;
}

.trips-map__micro-marker div {
    border: 2px solid #80808000;
    width: 100%;
    height: 100%;
    border-radius: inherit;
}