@import '../../vars.css';

.trip-plan-report {
}

.trip-plan-report__warning-text {
    color: #ff302f;
}

.trip-plan-report__driver-block {
    display: flex;
    align-items: center;
    gap: 7px;
}

.trip-plan-report__icon {
    display: flex;
    align-items: center;
    width: var(--svg-icon-size-m);
    height: var(--svg-icon-size-m);
}

.trip-plan-report__icon_yellow {
    stroke: var(--svg-icon-yellow);
}

.trip-plan-report__icon_blue {
    stroke: var(--svg-icon-blue);
}

.trip-plan-report .column-orders,
.trip-plan-report .column-products,
.trip-plan-report .column-delayCount,
.trip-plan-report .column-delayCount,
.trip-plan-report .column-status,
.trip-plan-report .column-adjustmentCount,
.trip-plan-report .column-movedCount,
.trip-plan-report .column-receivedCount {
    width: 7%;
}

.trip-plan-report .column-car {
    width: 18%;
}