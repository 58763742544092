.driver-logs__ext-content {
    display: flex;
    border-bottom: 1px solid #e8e8e8;
}

.driver-logs__ext-content:last-child {
    border-bottom: none;
}

.driver-logs__text {
    flex: 1 1 auto;
}

.driver-logs__time {
    flex: 0 1 auto;
    width: 50px;
}

.driver-logs__stack-trace {
    flex: 1 1 auto;
}

.driver-logs__ext-content:last-child .driver-logs__stack-trace {
    margin: 0;;
}

.driver-logs__ext-content {
    font-size: 13px;
    margin-bottom: 10px;
}

.driver-logs__stack-trace {
    font-size: 11px;
}

.driver-logs__method {
    color: #FF4E11;
}

.driver-logs__method.GET {
    color: #69B34C;
}

.driver-lines .recharts-tooltip-item-value {
    float: right;
    margin-left: 5px;
}