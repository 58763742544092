.driver-place-selector__marker {
    font-weight: 700;
    text-align: center;
    width: 18px !important;
    height: 18px !important;
    margin-top: -9px !important;
    margin-left: -9px !important;
    background-color: #ffc000;
    border-radius: 50%;
    border: 1px solid black;
}

.driver-place-selector__selected-marker {
    box-shadow: 0 0 5px 3px #28e135;
}

.driver-place-selector__marker-with-driver-place {
    background-color: #28e135;
}

.driver-place-selector__driver-marker {
    background-color: #28e135;
    font-size: 9px;
    line-height: 9px;
    width: 12px !important;
    height: 12px !important;
    margin-top: -6px !important;
    margin-left: -6px !important;
}

.driver-place-selector__path {
    stroke-width: 2;
}

.driver-place-selector__selected-path {
    stroke: #28e135;
}

.map-container .leaflet-grab {
    cursor: pointer;
}