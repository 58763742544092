.app-menu__item {
}

.app-menu__item_relative {
    position: relative;
}

.app-menu__message-counter {
    position: absolute;
    top: 7px;
    left: 10px;
    background: red;
    border-radius: 50%;
    width: 10px;
    height: 10px;
}

.app-menu .app-menu__item.app-menu__item_delimiter {
    margin: 0 0 70px 0;
}
