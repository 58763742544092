.wrapper {
    height: 100%;
    position: relative;
    text-align: end;
}

.wrapper table {
    font-size: 12px;
    height: 100%;
}

.wrapper thead th {
    position: sticky;
    top: 0;
    z-index: 1;
}

.container {
    position: absolute;
    left: 0;
    top: 40px;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
}

.bold {
    font-weight: bold;
}

.gray {
    color: #b4b4b4;
}