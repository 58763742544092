@import '../../vars.css';

.trip-plan-analysis .entity-list__cell {
    font-size: 12px;
    padding: 10px;
}

.trip-plan-analysis .entity-list__data-cell-row-color-marker {
    padding: 12px 0 6px 0 !important;
}

.trip-plan-analysis .worst-value {
    color: #ff302f;
}

.trip-plan-analysis .best-value {
    color: #008714;
}

.trip-plan-analysis__warning-text {
    color: #ff302f;
}

.orders-distribution .arrival,
.trip-plan-analysis .column-arrivalGTPlus60 .column-cell-bar {
    overflow: hidden;
    text-align: center;
    color: whitesmoke;
    line-height: 13px;
    font-size: 10px;
}

.orders-distribution .arrival-lt-minus30 {
    background-color: #FAB733;
}

.orders-distribution .arrival-btw-minus30-plus30 {
    background-color: #69B34C;
}

.orders-distribution .arrival-btw-plus31-plus60 {
    background-color: #FAB733;
}

.orders-distribution .arrival-gt-plus60,
.trip-plan-analysis .column-arrivalGTPlus60 .column-cell-bar {
    background-color: #FF4E11;
}

.trip-plan-analysis .entity-list__cell {
    width: auto;
}

.trip-plan-analysis .entity-list__cell.column-doneOrdersCount,
.trip-plan-analysis .entity-list__cell.column-notDoneOrdersCount {
    width: 150px;
}

.trip-plan-analysis .entity-list__cell.column-car {
    width: 300px;
}

.trip-plan-analysis .entity-list__cell.column-distance {
    width: 222px;
    padding-left: 52px;
}

.trip-plan-analysis .entity-list__cell.column-duration {
    width: 170px;
}

.trip-plan-analysis .entity-list__cell .cell-bar {
    background-color: #00000022;
    text-indent: 4px;
    line-height: 15px;
    height: 15px;
    font-size: 10px;
    white-space: nowrap;
    border-bottom: 1px solid #00000055;
    position: relative;
}

.trip-plan-analysis .entity-list__cell .cell-bar .label {
    position: absolute;
    width: 50px;
    left: -52px;
}

.trip-plan-analysis__car-block {
    display: flex;
    gap: 7px;
    align-items: center;
}

.trip-plan-analysis__icon-block {
    display: flex;
    align-items: center;
}

.trip-plan-analysis__icon {
    width: var(--svg-icon-size-m);
    height: var(--svg-icon-size-m);
}

.trip-plan-analysis__icon_yellow {
    stroke: var(--svg-icon-yellow);
}

.trip-plan-analysis__icon_blue {
    stroke: var(--svg-icon-blue);
}