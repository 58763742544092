.entity-list {
}

.entity-list__actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 32px;
}

.entity-list__actions a + a {
    margin-left: 15px;
}

.entity-list__filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;
}

.entity-list__filters-reverse {
    flex-direction: row-reverse;
}

.entity-list__select-filters,
.entity-list__search-filters {
    display: block;
}

.entity-list__select-filters:empty {
    display: none;
}


.entity-list__search-filters {
    width: 33%;
}

.entity-list__select-filter-item {
    min-width: 180px;
}

.entity-list__select-filter-item_not-empty .ant-select-selection {
    background-color: #e7f9ff;
}

.entity-list__select-filter-item:not(:last-child) {
    margin-right: 16px;
}

.entity-list__select-filter-item-tag {
    padding: 0 11px;
    line-height: 30px;
    font-size: 14px;
    background-color: #e7f9ff;
}

.entity-list__search-filters {
    min-width: 160px;
}

.entity-list__search-field_not-empty input {
    background-color: #e7f9ff;
}

.entity-list__pagination:not(:last-child) {
    margin-bottom: 16px;
}

.entity-list__table {
    width: 100%;
}

.entity-list__table:not(:last-child) {
    margin-bottom: 16px;
}

.entity-list__table-header {
    background-color: #e8e8e8;
}

.entity-list__header-cell {
    font-weight: 500;
}

.entity-list__row_warning {
    background-color: #fffbf7;
}

.entity-list__data-row {
    border: 1px solid #e8e8e8;
}

.entity-list__header-row {
    border: 1px solid #e8e8e8;
}

.entity-list__data-row_clickable {
    cursor: pointer;
}

.entity-list__data-row_clickable:hover {
    background-color: #e6f7ff;
}

.entity-list__data-row_selected-without-extension {
    background-color: #e6f7ff;
}

.entity-list__cell {
    width: 1px;
    padding: 5px;
    font-size: 14px;
}

.entity-list__small .entity-list__cell {
    padding: 0 8px;
}

.entity-list__cell_no-wrap {
    white-space: nowrap;
}

.entity-list__cell_stretch {
    width: auto;
}

.entity-list__cell_align-left {
    text-align: left;
}

.entity-list__cell_align-center {
    text-align: center;
}

.entity-list__cell_align-right {
    text-align: right;
}

.entity-list__cell svg {
    align-self: center;
}

.entity-list__data-cell-row-color-marker {
    height: 1px; /** https://stackoverflow.com/a/3542470/3639633 */
    padding: 20px 0 16px 0 !important;
}

.entity-list__row-color-marker {
    display: inline-block;
    width: 3px;
    height: 20px;
}

.entity-list__extension-cell {
    padding: 16px;
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
}

.entity-list__extension-cell .ant-spin {
    color: gray;
    text-align: center;
    width: 100%;
}

/* Ant overrides */

.entity-list .ant-table,
.entity-list .ant-table-thead > tr > th {
    color: #000;
}

.entity-list .ant-table-selection-column {
    display: none;
}

.entity-list .ant-table-tbody > tr.ant-table-row-selected td {
    background: #e7f9ff;
}

.entity-list_clickable-items
    .ant-table-thead
    > tr.ant-table-row-hover:not(.ant-table-expanded-row)
    > td,
.entity-list_clickable-items
    .ant-table-tbody
    > tr.ant-table-row-hover:not(.ant-table-expanded-row)
    > td,
.entity-list_clickable-items
    .ant-table-thead
    > tr:hover:not(.ant-table-expanded-row)
    > td,
.entity-list_clickable-items
    .ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row)
    > td {
    cursor: pointer;
}

.entity-list .ant-table-body > table {
    border: 1px solid rgba(0, 0, 0, 0.09);
    border-radius: 2px;
}

.entity-list:not(.entity-list_clickable-items)
    .ant-table-thead
    > tr.ant-table-row-hover:not(.ant-table-expanded-row)
    > td,
.entity-list:not(.entity-list_clickable-items)
    .ant-table-tbody
    > tr.ant-table-row-hover:not(.ant-table-expanded-row)
    > td,
.entity-list:not(.entity-list_clickable-items)
    .ant-table-thead
    > tr:hover:not(.ant-table-expanded-row)
    > td,
.entity-list:not(.entity-list_clickable-items)
    .ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row)
    > td {
    background: inherit;
}
.entity-list .ant-table colgroup > col.ant-table-selection-col {
    width: 0px;
}
/* 
.entity-list .ant-table table,
.ant-table-thead > tr > th.entity-list__cell {
    text-align: center;
} */

.entity-list .ant-table-row-expand-icon-cell {
    display: none;
}

.entity-list .ant-table-expanded-row td:first-child {
    display: none;
}

.entity-list .cell-bar-chart {
    display: flex;
    flex-direction: row;
    height: 40px;
}

.entity-list .cell-bar-chart .bar {
    margin-top: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    line-height: 1em;
    min-height: 1px;
}

.entity-list .column-cell-bar {
    background-color: #00000022;
    text-indent: 5px;
    min-width: 1px;
}