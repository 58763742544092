.chart-driver-list .entity-list__cell {
    font-size: 12px;
    padding: 10px;
    width: auto;
}

.chart-driver-list tbody .entity-list__cell {
    vertical-align: bottom;
}

.chart-driver-list tbody .entity-list__cell.column-name,
.chart-driver-list tbody .entity-list__cell.column-completedOrdersCount,
.chart-driver-list tbody .entity-list__cell.column-notCompletedOrdersCount,
.chart-driver-list tbody .entity-list__cell.column-errorActionsCount {
    vertical-align: inherit;
}

.chart-driver-list .column-arrivalGTPlus60Ratio .column-cell-bar,
.chart-driver-list .column-errorActionsCount .column-cell-bar {
    background-color: #FF4E11AA;
}

.chart-driver-list .bar {
    font-size: 10px;
    width: 10px;
    margin-right: 1px;
}

.chart-driver-list .column-lastArrivalGTPlus60s .bar {
    background-color: #FF4E11;
}

.chart-driver-list .column-lastArrivalBtwPlus31Plus60s .bar,
.chart-driver-list .column-lastArrivalLTMinus30s .bar {
    background-color: #FAB733;
}

.chart-driver-list .column-lastArrivalBtwMinus30Plus30s .bar {
    background-color: #69B34C;
}

.chart-driver-list .column-arrivalBtwPlus31Plus60Ratio {
    width: 150px;
}