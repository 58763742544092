.wrapper {
    position: relative;
    height: 100%;
}

.container {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
}

.ordersContainer {
    position: relative;
    flex-basis: 400px;
}

.orderList {
    height: 100%;
    overflow-y: scroll;
}

.button {
    position: absolute;
    bottom: 5px;
    right: 10px;
}

.map {
    flex: 1;
}