.trip-report {
}

.trip-report__trip-status-badge {
    margin-left: 8px;
}

.trip-report__summary {
    display: flex;
    border: 1px solid rgb(217, 217, 217);
}

.trip-report__summary-column {
    flex: 0 1 auto;
    padding: 16px;
}

.trip-report__summary-column:not(:last-child) {
    border-right: 1px solid rgb(217, 217, 217);
}

.trip-report__summary-header {
    margin-bottom: 8px;
    font-weight: 600;
    color: rgb(102, 102, 102);
}

.trip-report__summary-subcolumns-container {
    display: flex;
    justify-content: space-between;
}

.trip-report__summary-subcolumn {
    flex: 0 1 auto;
}

.trip-report__summary-subcolumn:not(:last-child) {
    margin-right: 120px;
}

.trip-report__summary-item {
    display: flex;
    justify-content: space-between;
}

.trip-report__summary-item:not(:last-child) {
    margin-bottom: 4px;
}

.trip-report__summary-item-title {
    margin-right: 16px;
}

.trip-report__summary-item-value {
}

.trip-report__warning-text {
    color: #ff302f;
}

.trip-report__title {
    margin: 20px 0 -35px !important;
    font-weight: 400 !important;
}