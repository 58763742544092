.trip-plan-item-view-route
    > .page__content
    > .page__content-wrapper
    > .ant-spin-nested-loading {
    min-height: 100%;
}

.trip-plan-item-view-route
    > .page__content
    > .page__content-wrapper
    > .ant-spin-nested-loading
    > * {
    display: flex;
    flex-direction: column;
}

.trip-plan-item-view-route__tabs {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
}

.trip-plan-item-view-route__trip-plan-details {
    flex: 1 0 auto;
}

.trip-plan-item-view-route__trip-plan-realtime-container {
    position: relative;
    flex: 1 1 auto;
    height: 100%;
}

.trip-plan-item-view-route__trip-plan-realtime {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
