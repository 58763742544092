.ant-spin-nested-loading {
    width: 100%;
    height: 100%;
}

.ant-spin-container {
    width: 100%;
    height: 100%;
}

.zone__page {
    width: 100%;
    height: 100%;
}