.trip-list {
}

.trip-list__driver-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.trip-list__driver-name{
    width: 250px;
    margin-left: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.trip-list__driver-cell{
    width: 100%;
}

.trip-list__driver-number {
    margin-top: 2px;
    margin-right: 3px;
    font-size: 12px;
    font-weight: bold;
}

.trip-list__day-route {
    color: coral;
}

.trip-list__evening-route {
    color: royalblue;
}

.trip-list__trip-parameter {
    display: flex;
    flex-direction: row;
}

.trip-list__trip-parameter-text-loading {
    margin-bottom: -10px;
}

.trip-list__trip-parameter-icon {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 auto;
}

.trip-list__trip-parameter-text {
}

.trip-list .car-loading-info {
    width: 100%;
    padding-top: 4px;
    vertical-align: middle;
}

.trip-list__table{
    display: table-cell;
    flex-direction: column;
}

.trip-list__upper-row{
    display: flex;
    flex-direction: row;
}

.trip-list__lower-row{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.trip-plan-details__route-info-container_title {
    margin-bottom: 8px;
}