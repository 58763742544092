.wrapper {
    position: relative;
    height: 100%;
    padding-top: 30px;
}

.container {
    height: 100%;
}

.dateSelector {
    display: inline-block;
    margin-right: 20px;
}

.select {
    width: 250px;
}

.tripsByDate {
    display: inline-block;
}

.noTrips {
    padding-top: 6px;
}

.analyzedTrips {
    width: 800px;
    margin-top: 40px;
}

.pagination {
    margin-bottom: 20px;
}