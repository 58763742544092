.routed-tabs .ant-tabs-extra-content {
    display: none;
}

.routed-tabs .ant-tabs-nav svg {
    width: auto;
    height: auto;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    background: #E8E8E8;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
    background: none;
}