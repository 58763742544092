.basket {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid lightgrey;
    min-height: 50px;
    cursor: pointer;
}

.basket:hover {
    background: #e6f7ff;
}

.basket:not(:first-child) {
    border-top: none;
}

.markSection {
    flex: 0 0 3px;
    height: 20px;
    align-self: center;
}

.name {
    flex: 1;
    padding-left: 10px;
}

.counter {
    display: flex;
    align-self: stretch;
    align-items: center;
    background: #e6f7ff;
    padding: 0 10px;
}

.icon {
    width: 24px;
    height: 24px;
}