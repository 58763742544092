.details {
    font-size: 12px;
    line-height: 1;
}

.details > div {
    margin-bottom: 5px;
}

.driver {
    font-weight: 900;
}