.district-strict-interval-popup__input {
  margin-bottom: 13px;
}

.district-strict-interval-popup__buttons {
  display: flex;
  justify-content: space-between;
  margin: 23px 0 13px 0;
}

.district-strict-interval-popup__time-input {
  margin-bottom: 13px;
}

.district-strict-interval-popup__times {
  margin-bottom: 13px;
}

.district-strict-interval-popup__time-input .ant-picker {
  width: 84px;
}

.district-strict-interval-popup__plus-button {
  font-size: 16px; 
  color: green;
}

.district-strict-interval-popup__delete-interval-button {
  margin-left: 10px;
  color: red; 
}