/* TODO: authenticated-app */

.authenticated-app {
    height: 100%;
}

.authenticated-app__toggle-sider-button {
    position: absolute;
    bottom: 8px;
    left: 8px;
}

.authenticated-app__content {
    position: relative;
    display: flex;
    background-color: #fff;
}

.authenticated-app__content.ant-layout-content {
    min-height: auto;
}

.authenticated-app__content > div {
    flex: 1;
}

.authenticated-app__footer {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.authenticated-app__footer.ant-layout-footer {
    flex: 0 1 auto;
}

.order-count-prediction {
    position: absolute;
    top: 0;
    right: 0;
}