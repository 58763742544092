.driverItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1px 10px;
    border: 1px solid lightgray;
    cursor: pointer;
}

.driverItem:not(:last-child) {
    border-bottom: none;
}

.name {
    margin: 0 10px 0 0 ;
    white-space: nowrap;
    overflow: hidden;
}

.shipment {
    display: flex;
    justify-content: space-between;
    flex: 0 0 90px;
    padding-top: 4px;
    border-left: 1px solid gray;
}

.shipmentItem {
    padding-left: 5px;
    font-size: 11px;
}

.icon {
    width: 20px;
    height: 20px;
}

.count {
    text-align: center;
}

.selected {
    box-shadow: 0 0 3px 3px yellow inset;
}

.warning {
    background: #ffb5b5;
}