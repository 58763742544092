.district-list-item {
  display: flex;
  border: 1px solid lightgray;
  padding-right: 10px;
  cursor: pointer;
}

.district-list-item_selected {
  box-shadow: 0 0 3px 3px yellow inset;
}

.district-list-item:not(:last-child) {
  border-bottom: none;
}

.district-list-item:hover {
  background-color: #e6f7ff;
}

.district-list-item__color {
  align-self: center;
  height: 25px;
  width: 3px;
  margin-right: 8px;
}

.district-list-item__container {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
}

.district-list-item__button-margin {
  margin-left: 5px;
}

.district-list-item__button-group {
  align-self: stretch;
  padding-top: 4px;
}

.district-list-item__container_name {
  flex: 1;
}