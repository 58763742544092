.trip {
    position: relative;
    padding: 10px 110px 10px 10px;
    background-color: #f3f3f3;
    border-bottom: 1px solid white;
    display: flex;
}

.tripName {
    flex: 1 0;
}

.button {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    margin: auto 0;
}

.visible {
    display: inline-block;
}

.invisible {
    display: none;
}