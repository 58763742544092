.graphs-modal.ant-modal, .graphs-modal .ant-modal-content {
    height: 100vh;
    width: 100vw;
    margin: 0;
    top: 0;
}

.graphs-modal .ant-modal-body {
    height: calc(100vh - 110px);
    overflow: scroll;
}

.graphs-modal .ant-modal-body svg {
    width: 100%;
    height: auto;
}