.trip-analysis {
    font-size: 13px;
}

.trip-analysis__maps-group {
    display: flex;
    align-items: stretch;
    height: 550px;
}

.trip-analysis__maps-group > div {
    margin-right: 10px;
    flex: 1;
}

.trip-analysis__maps-group > div:last-child {
    margin-right: 0;
}

.trip-analysis__map-button {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 14px;
}

.trip-analysis__route-block {
    display: flex;
    gap: 20px;
}

.trip-analysis__route .entity-list__header-row {
    border: 1px solid #e8e8e8;
}

.trip-analysis__route .entity-list__cell {
    width: auto;
    font-size: 12px;
    padding: 14px 2px;
    white-space: nowrap;
}

.trip-analysis__route .entity-list__cell.column-index {
    padding-left: 10px;
    padding-right: 0;
}

.trip-analysis__route .entity-list__cell.column-id {
    padding-left: 3px;
    padding-right: 0;
}

.trip-analysis__route td.entity-list__cell.column-index {
    font-size: 10px;
    line-height: 19px;
}

.trip-analysis__route .entity-list__cell.column-promisedDateTimePeriod,
.trip-analysis__route .entity-list__cell.column-desiredDateTimePeriod,
.trip-analysis__route .entity-list__cell.column-arrivedAt {
    text-align: center;
}

.trip-analysis__route__actual .entity-list__cell.column-address {
    width: 230px;
}

.trip-analysis__route .entity-list__cell.column-doneAtAddress span {
    vertical-align: middle;
}

.trip-analysis__route .entity-list__cell.column-arrivedAt{
    font-weight: bold;
}

.trip-analysis__route .entity-list__cell.column-status .ant-tag {
    font-size: 10px;
    margin: 0;
    padding: 0 3px;
    line-height: inherit;
}

.trip-analysis__route .entity-list__cell:last-child {
    padding-right: 10px;
}

.trip-analysis__route-title {
    font-style: normal;
    font-weight: bold;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 12px;
}

.trip-analysis__route__address {
    height: 1.4em;
    line-height: 1.4em;
    overflow: hidden;
    max-width: 250px;
    text-overflow: ellipsis;
    direction: rtl;
}

.trip-analysis__alert {
    margin-top: 32px;
}

.trip-analysis__summary {
    border: 1px solid rgb(232, 232, 232);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    justify-content: space-between;
    height: 56px;
}

.trip-analysis__summary-column {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 50%;
    line-height: 55px;
}

.trip-analysis__similarity-column {
    display: flex;
    line-height: inherit;
    flex-direction: column;
    padding: 0 5px 5px 10px;
}

.trip-analysis__summary-column:not(:last-child) {
    box-shadow: inset -1px 0 0 rgb(232, 232, 232);
}

.trip-analysis__summary-title {
    font-weight: 500;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.8)
}

.trip-analysis__summary-title {
    height: 1em;
}

.trip-analysis__summary-data {
    font-size: 12px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8);
}

.trip-analysis__similarity-data {
    white-space: nowrap;
    height: 1em;
}

.trip-analysis__distance-block {
    width: 48px;
    height: 16px;
    border-radius: 4px;
    background-color: #E8E8E8;
    text-align: center;
    font-size: 12px;
    line-height: 135%;
    color: #333333;
    margin: -23px auto 7px;
}

.trip-analysis__hide-block {
    display: none;
}

.trip-analysis__route__actual .entity-list__row_warning {
    background-color: #f5e2d1;
}

.trip-analysis__trip-info-block {
    margin-bottom: 10px;
}

.trip-analysis__trip-info-block span {
    display: inline-block;
    width: 100px;
}

.trip-analysis__route__time-start-not-active .time-start {
    opacity: 0.7;
}

.trip-analysis .entity-list__data-row.even-group {
    background-color: #90EE90;
}

.trip-analysis .entity-list__data-row.odd-group {
    background-color: #adfcad;
}

.trip-analysis__chart-block {
  display: flex;
  flex-direction: column;
}

.trip-analysis__route-vis-mode .ant-radio-wrapper {
    margin-right: 0;
}

.trip-analysis__route-vis-mode .ant-radio-wrapper span.ant-radio + * {
    padding-right: 0;
}

.trip-analysis__warning-label {
    color: red;
}

.trip-analysis__attention-label {
    color: orange;
}

.trip-analysis__success-label {
    color: green;
}

.trip-analysis__disable-label {
    color: grey;
}