.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.container {
    display: flex;
    height: 100%;
}

.listWrapper {
    position: relative;
    flex-basis: 400px;
    height: 100%;
}

.list {
    position: absolute;
    top: 40px;
    bottom: 35px;
    left: 0;
    right: 0;
    overflow: scroll;
}

.mapTabs {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.map {
    flex: 1 1;
    height: 100%;
    margin-top: -16px;
}

.listItem {
    display: flex;
    border: 1px solid lightgrey;
    align-items: center;
    border-bottom: none;
    padding: 0 10px 0 0;
    gap: 7px;
    min-height: 45px;
    font-size: 13px;
}

.listItem:last-child {
    border-bottom: 1px solid lightgrey;
}

.driverColor {
    align-self: stretch;
    width: 10px;
}

.driverName {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}

.cityDistrict {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    border: 1px solid lightgray;
    padding: 5px 10px;
    background: #ececec;
    font-weight: bold;
}

.ordersCount {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 3px;
}

.bold {
    font-weight: bold;
}

.ordersCountByDepartPeriod {
    display: flex;
    align-items: center;
    gap: 3px;
    height: 15px;
}

.bottle {
    display: inline-block;
    line-height: 0.5;
    font-size: 10px;
    padding: 1px 1px 3px 1px;
    text-align: center;
    border: 1px solid gray;
    border-radius: 4px;
}

.lightWater {
    background: #d4f1f9;
}

.darkWater {
    background: #1C4761;
    color: white;
}

.bottle::before, .bottle::before {
    content: '';
    display: block;
    width: 10px;
    height: 4px;
    background: inherit;
    position: relative;
    top: -5px;
    border: 1px solid gray;
    left: 50%;
    transform: translateX(-50%);
}

.shipmentCount {
    display: flex;
    width: 60px;
    justify-content: center;
}

.shipmentCountHigh {
    background: #ffb5b5;
}

.shipmentCountLow {
    background: #b5ffb5;
}

.buttonWarning {
    font-size: 25px;
    background-color: black;
}

.warning {
    color: #ff0000;
}

.iconLarge svg {
    width: 30px;
    height: 30px;
}

.infoClose {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #ff0000;
}

.infoContainer {
    background-color: white;
    min-width: 300px;
    padding: 15px;
    border-radius: 10px;
    overflow: scroll;
}

.infoName {
    font-size: 17px;
}

.infoTitle {
    font-size: 14px;
    font-weight: 900;
    font-style: italic;
}

.infoItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.popover {
    max-height: 300px;
    overflow-y: scroll;
}

.popoverItem {
    cursor: pointer;
    height: 30px;
}

.popoverInactive {
    color: grey;
}

.infoDriverBlock {
    width: 300px;
    padding-bottom: 20px;
    margin-bottom: 15px;
    border-bottom: 1px solid black;
}

.infoBlock {
    margin-bottom: 20px;
}

.btnBuild {
    display: flex;
    position: absolute;
    bottom: 0;
    right: 3px;
    gap: 10px;
}

.bolder {
    font-weight: bolder;
}

.lineHeight {
    line-height: 1;
}

.orderList {
    border-radius: 7px;
    font-size: 11px;
}

.orderListItemContainer {
    padding: 5px;
    gap: 15px
}

.btnMoveOrders {
    margin-top: 15px
}