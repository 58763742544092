.route-info-block {
    display: flex;
    min-height: 50px;
    cursor: pointer;
}

.route-info-block_active {
    background-color: #e7f9ff;
}

.route-info-block__marker_start,
.route-info-block__marker_end,
.route-info-block__marker_route-point {
    flex: 0 0 30px;
    position: relative;
}

.route-info-block__distance {
    position: absolute;
    top: -7px;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;
    z-index: 10;
    text-align: center;
    font-size: 10px;
    line-height: 10px;
    color: #1890ff;
    padding: 2px;
}

.route-info-block__drag {
    flex: 0 0 25px;
    display: flex;
    justify-content: flex-end;
    align-items: center;    
}

.route-info-block__content {
    flex: 1;
    display: flex;
    align-items: center;
    border-bottom: 1px solid lightgrey;
    padding: 8px 8px 8px 0;
    line-height: 17px;
}

.route-info-block__content_end {
    border-bottom: none;
}

.route-info-block__client-name {
    color: grey;
}

.route-info-block__order-status {
    max-width: 145px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
}

.route-info-block__wrapper-circle {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: calc(50% - 6px);
    left: calc(50% - 6px);
    width: 13px;
    height: 13px;
}

.route-info-block__inner-circle {
    width: 9px;
    height: 9px;
    border: 1px solid #1890ff;
    border-radius: 50%;
    background-color: white;
    position: relative;
}

.route-info-block__inner-circle_done {
    border: 1px solid #4acc56;
    background-color: #4acc56;
}

.route-info-block__inner-circle_fail {
    border: 1px solid red;
    background-color: red;
}

.route-info-block__inner-circle_scheduled {  
    width: 13px;
    height: 13px;
    background-color: #1890ff;
    position: relative;
}

.route-info-block__inner-circle_scheduled::before {  
    content: "";
    position: absolute;
    display: inline-block;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    border: 1px solid #ffffff;
    background-color: #1890ff;
}

.route-info-block__marker_route-point::before,
.route-info-block__marker_start::before,
.route-info-block__marker_end::before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 1px;
    top: 8px;
    bottom: 8px;
    left: 50%;
    background-color: #1890ff;
}

.route-info-block__marker_start::before {
    height: calc(50% - 8px);
    top: 50%;
    background-color: #4acc56;
}

.route-info-block__marker_end::before {
    height: calc(50% - 8px);
}

.route-info-block__inner-icon {
    position: absolute;
    top: 1px;
    width: 7px;
    height: 5px;
    color: #fff;
}

.route-info-block__inner-icon_fail {
    width: 8px;
}