.marker {
    font-weight: 700;
    text-align: center;
    width: 14px !important;
    height: 14px !important;
    margin-top: -7px !important;
    margin-left: -7px !important;
    background-color: #28e135;
    border-radius: 50%;
    border: 1px solid black;
    font-size: 9px;
    line-height: 10px;
}

.path {
    stroke-width: 3;
}

.selectedPath {
    stroke-width: 7;
}

.mapControl {
    font-size: 17px;
}