.sun {
    width: 20px;
    height: 20px;
    stroke: var(--svg-icon-yellow);
}

.moon {
    width: 20px;
    height: 20px;
    stroke: var(--svg-icon-blue);
}