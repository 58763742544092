.login-form {
    position: relative;
    width: 304px;
    padding: 40px 0 60px;
}

.login-form__alert {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.login-form__inputs-block {
    padding-bottom: 8px;
}

.login-form__login-button {
    width: 100%;
    padding-bottom: 2px;
}
