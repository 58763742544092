.list {
    margin: 0 auto;
    border: 1px solid lightgray;
    border-top: none;
}

.item {
    padding: 5px 10px 5px 20px;
    display: flex;
    flex-direction: row;
}

.warning {
    background: #ffdddf;
}

.address {
    flex: 1 1;
}

.period {
    flex: 0 0 100px;
    display: flex;
    align-items: center;
}