.store-list__container {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.store-list__editor {
    flex: 0 1 400px;
    height: calc(100% - 40px);
    overflow-y: scroll;
}

.store-list__map-container {
    flex: 1 0 auto;
    height: 100%;
}

.store-list__map-container .leaflet-container {
    border-radius: 4px;
}

.store-list__item {
    border: 1px solid lightgrey;
    padding: 0 10px;
}

.store-list__item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
    cursor: pointer;
}

.store-list__item:not(:first-child) {
    border-top: none;
}

.store-list__item_delete {
    margin-left: 10px;
}

.store-list__save-button {
    position: absolute;
    bottom: 0;
}

.store-list__item_warning {
    color: red;
}

.store-list__item-address {
    max-width: 300px;
}

.store-list__item-coords {
    color: gray;
}

.store-list__item-buttons {
    height: 100%;
    display: flex;
    align-items: center;
}

.store-list__item-icon {
    width: 25px;
    height: 25px;
    fill: lightgray;
}

.store-list__item-main-icon {
    fill: gold;
}