.validation-list__orders-block {
    display: flex;
    flex-direction: row;
    min-height: 595px;
}

.validation-list__orders {
    margin-right: 20px;
}

.validation-list__orders {
    flex: 1 1 40%;
}

.validation-list__car-list,
.validation-list__address-edit {
    flex: 1 1 60%;
    position: relative;
    min-width: 620px;
}

.validation-list__no-new-orders-alert {
    margin-top: 32px;
    margin-bottom: 32px;
}