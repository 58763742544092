.plan-map__map_container {
    width: 100%;
    height: 100%;
}

.plan-map__marker-tooltip {
    padding: 2px 8px;
    background-color: #fff;
}

.plan-map__car-marker {
    width: 0 !important;
    height: 0 !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
}

.plan-map__car-marker svg {
    position: absolute;
    top: -10px;
    left: -10px;
    font-size: 20px;
}

.plan-map__car-marker-tooltip {
    margin-top: 33px;
    margin-left: -30px;
    padding: 4px 8px;
    font-size: 11px;
    line-height: 1.3;
    border-radius: 0 8px 8px 8px;
    opacity: 1 !important;
    max-width: 15ch;
    text-overflow: ellipsis;
    overflow: hidden;
}

.plan-map__car-marker-tooltip_red {
    background: #FF0000;
    z-index: 20;
}

.plan-map__car-marker-tooltip_orange {
    background: #FFBA08;
    z-index: 10;
}

.plan-map__car-marker-tooltip:hover {
    max-width: none;
}

.plan-map__car-marker-tooltip:before {
    display: none;
}

.plan-map__button {
    position: absolute;
    display: block;
    width: 34px;
    height: 32px;
    line-height: 30px;
    left: 0;
    top: 0;
    z-index: 10;
    background-color: #fff;
    padding: 3px;
    border: 2px solid rgba(0,0,0,0.2);
    border-radius: 4px;
    cursor: pointer;
}

.plan-map__button.active {
    color: #1890ff;
}

.plan-map__view-mode {    
    left: 10px;
    top: 108px;    
}

.plan-map__districts-visibility {
    left: 10px;
    top: 144px;   
}

.plan-map__actual-route-button {
    font-size: 11px;
    line-height: 24px;
    left: auto;
    right: 10px;
    top: 10px;
}