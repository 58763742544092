.map-marker__content {
    font-size: 10px;
    font-weight: 700;
    text-align: center;
}

.place-select__map-path-point_label {
    width: 14px !important;
    height: 14px !important;
    margin-top: -7px !important;
    margin-left: -7px !important;
    background-color: #ffc000;
    border-radius: 50%;
}

.place-select__map-path-point_no-label {
    width: 8px !important;
    height: 8px !important;
    margin-top: -4px !important;
    margin-left: -4px !important;
    background-color: #ffc000;
    border-radius: 50%;
}

.place-select__map-path_red {
    stroke: #ff000083;
    stroke-width: 3;  
}

.place-select__map-path_orange {
    stroke: rgb(253, 200, 25);
    stroke-width: 3;  
}
