.place-validation-page-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}
.place-validation-page {
    display: flex;
    flex-direction: row;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.place-validation__entity-list{
    width: 550px;
    overflow: hidden;
    overflow-y: scroll;
}

.place-validation-page .place-select {
    flex: 1;
    height: auto;
}

.place-validation-page .place-select__topbar {
    flex: 0 50px;
    margin-bottom: 6px;
    padding: 0;
}

.place-validation__entity-list .entity-list__cell {
    width: auto;
    padding: 8px;
    font-size: 12px;
}

.place-validation__entity-list .column-driverName a {
    text-decoration: underline;
    text-decoration-style: dotted;
}

.place-validation-page .place-select__map-container {
    flex: 1 1;
}

.place-validation-page .leaflet-map-content__right {
    right: 11px;
}