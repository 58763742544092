.header {
    font-size: 12px;
    line-height: 22px;
    font-weight: bold;
    margin-bottom: 14px;
}

.btn {
    width: 100%;
    margin-top: 20px;
}