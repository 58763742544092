* {
    box-sizing: border-box;
}

* ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

* ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

* ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

html,
body,
#root,
.root,
.root__app {
    height: 100%;
}

.controls-form {
    padding: 16px;
}

.controls-form textarea {
    resize: none !important;
}

/* Ant overrides */

body {
    color: #000;
}

svg {
    width: 16px;
    height: 16px;
}

.recharts-wrapper svg {
    width: inherit;
    height: inherit;
}

.ant-menu-item svg,
.ant-btn svg,
.ant-calendar-picker-icon svg,
.ant-time-picker-icon svg,
.ant-select-arrow-icon svg,
.ant-pagination-item-link svg,
.ant-input-number-handler svg {
    width: auto;
    height: auto;
}

.big-marker {
    width: 28px !important;
    height: 28px !important;
    margin-left: -14px !important;
    margin-top: -14px !important;
    border-radius: 50%;
    transition: all 0.8s ease;
}