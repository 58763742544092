.itineraries-list__block {
    padding-top: 30px;
}

.itineraries-list__block > button {
    margin-bottom: 30px;
    min-width: 100px;
}

.itineraries-list__item {
    margin-bottom: 50px;
}

@media print {
    .itineraries-list__block {
        padding-top: 0;
    }

    .itineraries-list__block .itineraries-list__item {
        page-break-after: always;
    }

    .itineraries-list__block .ant-btn,
    .ant-typography {
        display: none;
    }
}