.wrapper {
    background-color: #f3f3f3;
    border: 1px solid #f3f3f3;
    border-bottom: 1px solid white;
    cursor: pointer;
}

.selected,
.wrapper:hover{
    border: 1px solid red;
    border-radius: 3px;
}

.elem {
    display: flex;
    padding: 12px 20px;
    align-items: center;
}

.name {
    flex: 1 0;
}

.plus {
    color: green;
    font-weight: bold;
}

.buttons {
    display: flex;
    flex-direction: column;
}

.buttons button {
    margin: 3px 0;
    width: 145px;
}

.routeMetrics {
    flex: 1 0;
}

.routeMetrics div {
    font-size: 12px;
}

.routeMetrics div span{
    color: #2d7232;
}

.storeIcon {
    margin-left: auto;
    margin-right: 10px;
    flex: 0 0 30px;
    text-align: right;
}

.inputs {
    padding: 0 15px 15px;
}

.flex {
    display: flex;
    font-size: 10px;
}

.comment {
    flex: 0 0 75%;
    padding-right: 10px;
    align-self: stretch;
}

.textarea {
    height: calc(100% - 15px) !important;
}

.adjustments {
    flex: 0 0 25%;
}

.saveButton {
    display: block;
    margin-top: 10px;
    margin-left: auto;
    margin-right: 0;
}