.title {
    font-size: 14px;
    font-weight: 900;
    font-style: italic;
    margin-bottom: 3px;
}

.districtTitle {
    height: 32px;
    font-size: 17px;
}

.warningText {
    color: red;
}

.container {
    margin-bottom: 20px;
}

.color {
    display: flex;
}

.colorPicker {
    flex: 1;
    margin-right: 20px;
}

.colorCode {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1%;
}

.colorText {
    text-align: center;
}

.colorWarning {
    position: absolute;
    bottom: 33px;
    font-size: 11px;
    text-align: center;
    color: red;
}

.select {
    width: 100%;
}

.divider {
    margin: 8px 0;
}

.space {
    padding: 0 8px 4px;
}