.chart-route__reload {
    width: 25px;
    margin-left: 5px;
    line-height: 36px;
    cursor: pointer;
    color: #40a9ff;
}

.chart-route__container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.chart-route__date-period-picker {
    margin-bottom: 20px;
}

.chart-route__tabs-container {
    flex: 1;
    display: flex;
    flex-direction: column;
}