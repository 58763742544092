.working-day-list__pagination {
    margin-bottom: 15px;
}

.working-day-list__today {
    font-weight: bold;
}

.working-day-list__short_day,
.working-day-list__short_day:hover {
    background-color: #fab73333;
}

.working-day-list__rest_day,
.working-day-list__rest_day:hover {
    background-color: #ff4e1133;
}

.working-day-info {
    display: flex;
    gap: 5px;
    margin-bottom: 15px;
}

.working-day-info .ant-alert {
    border-radius: 5px;
}
