.order-info {
	position: relative;
	width: 352px;
	padding: 16px 16px 24px;
	background-color: #fff;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	overflow-y: auto;
	color: rgba(0, 0, 0, 0.8);
	padding-top: 40px;
}

.order-info_min {
	padding-top: 7px;
}

.order-info__buttons-group {
	position: absolute;
	top: 8px;
	right: 11px;
	border: none;
	background: none;
	display: flex;
}

.order-info__buttons-group_button-square,
.order-info__buttons-group_button-line,
.order-info__buttons-group_button-close {
	width: 14px;
	height: 14px;
	margin-left: 8px;
	cursor: pointer;
	position: relative;
}

.order-info__buttons-group_button-square {
	border: 2px solid #333333;
	border-radius: 2px;
}

.order-info__buttons-group_button-line {
	border-bottom: 2px solid #333333;
}

.order-info__buttons-group_button-close:before, .order-info__buttons-group_button-close:after {
	content: ""; 
	position: absolute; 
	width: 18px; 
	height: 2px; 
	top: 6px; 
	left: -2px; 
	background: black;
}

.order-info__buttons-group_button-close:before {transform: rotate(45deg);}
.order-info__buttons-group_button-close:after {transform: rotate(-45deg);}

.order-info__main-button {
	width: 100%;
}

.order-info__cancel-button {
	width: 100%;
	margin-top: 10px;
}

.order-info__line2 {
	position: relative;
	margin: 0 0 8px;
	padding-left: 32px;
	font-size: 16px;
	line-height: 120%;
}

.order-info__icon {
	position: absolute;
	top: 0;
	left: 0;
	width: 24px;
	height: 24px;
}

.order-info__icon-button_small {
	margin-left: 10px;
	width: 12px;
	height: 12px;
	cursor: pointer;
}

.order-info__text-client {
	max-width: 259px;
}

.order-info__secondary-text2 {
	font-size: 14px;
	opacity: 0.45;
}

.order-info__block-subheader2 {
	margin-bottom: 10px;
	font: normal 500 14px/120% 'Roboto', sans-serif;
	color: #828282;
}

.order-info__operation-block {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	position: relative;
	margin: 0 0 8px;
	padding-left: 32px;
}

.order-info__operation-block-icon {
	position: absolute;
	top: 3px;
	left: 0;
}

.order-info__operation-block-text {
	font-size: 16px;
	line-height: 120%;
	color: rgba(0, 0, 0, 0.8);
}

.order-info__operation-block > *:not(:last-child) {
	margin-right: 16px;
}

.order-info__driver-item {
	position: relative;
	border: 1px solid #e8e8e8;
	height: 43px;
	padding-left: 32px;
	cursor: pointer;
}

.order-info__block_text-hidden {
	font-size: 10px;
	color: white;
}

.order-info__executor-data2 {
	margin-left: 16px;
	line-height: 14px;
}