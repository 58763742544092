.leaflet-pane {
    z-index: 0;
}

.trip-plan-realtime {
    display: flex;
}

.trip-plan-realtime__none {
    display: none;
}

.trip-plan-realtime__sidebar {
    flex: 0 1 auto;
    width: 415px;
    margin-right: 16px;

    display: flex;
    flex-direction: column;
}

.trip-plan-realtime__sidebar-list {
    flex: 1;
    overflow: scroll;
}

.trip-plan-realtime__driver-name,
.trip-plan-realtime__orders {
    font-size: 12px;
}

.trip-plan-realtime__warning-text {
    color: #ff3030;
}

.trip-plan-realtime__status {
    width: 100%;
    text-align: center;
}

.trip-plan-realtime__resume-button {
    font-size: 10px;
    width: 100%;
    margin-top: 8px;
}

.trip-plan-realtime__flex-block {
    display: flex;
    align-items: center;
}

.trip-plan-realtime__flex-block > div:first-child {
    margin-right: 10px;
}

.trip-plan-realtime__map-container {
    flex: 1 1 auto;
    position: relative;
}

.trip-plan-realtime__map {
    width: 100%;
    height: 100%;
}

.trip-plan-realtime__checkpoint-icon {
    width: auto;
    height: auto;
}

.trip-plan-realtime__order-details-container {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1000;
}

.trip-plan-realtime__top {
    display: flex;
    gap: 20px;
    margin-bottom: 16px;
}

.trip-plan-realtime__route-info-panel {
    display: flex;
    justify-content: space-between;
}

.trip-plan-realtime__route-info-panel .ant-btn {
    flex: 0 1 auto;
}

.trip-plan-realtime__route-info-panel .ant-checkbox-wrapper {
    align-self: center;
    display: flex;
    align-items: flex-end;
    flex-direction: row-reverse;
}

.trip-plan-realtime__transfer-orders-block {
    position: sticky;
    bottom: 0;
    padding: 8px 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.trip-plan-realtime__transfer-orders-block .ant-btn {
    width: 150px;
}

.trip-plan-realtime__open-itinerary {
    margin-top: 2px;
    height: 16px;
}

.trip-plan-realtime__driver-number {
    font-size: 12px;
    font-weight: bold;
}

.trip-plan-realtime__work-shift {
    height: 35px;
    width: 35px;
}

.trip-plan-realtime__buttons-route-info-container {
    display: flex;
    justify-content: space-between;
}

.trip-plan-realtime_blue svg {
    color: #1890ff;
}

.trip-plan-realtime_gray-gradient {
    background: linear-gradient(90deg, #ffffff 0%, #e0e0e0 100%);
}