.district-strict-interval-list__item {
  width: 100%;
  border: 1px solid lightgray;
  padding: 0 10px;
  cursor: pointer;
}

.district-strict-interval-list__item_active {
  background-color: #80f8f852;
}

.district-strict-interval-list__item:not(:last-child) {
  border-bottom: none;
}

.district-strict-interval-list__item_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

.district-strict-interval-list__district-no-name {
  color: #cf1322;
}

.district-strict-interval-popup__time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  padding: 5px 0;
}