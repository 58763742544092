.product-operation {
    margin: -8px;
    padding: 8px;
    white-space: nowrap;
    cursor: help;
}

.product-operation__icon {
    vertical-align: sub;
}

.product-operation__icon_bottle svg {
    width: 15px;
    height: 20px;
}

.product-operation__icon_return svg {
    width: 15px;
    height: 17px;
}
