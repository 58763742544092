.drawer {
    display: flex;
    align-items: stretch;
    height: 100%;
}

.drivers {
    flex: 0 0 345px;
    padding: 5px;
    overflow-y: auto;
}

.card svg {
    width: 32px;
    height: 32px;
}

.card {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    padding: 10px;
}

.markUnread {
    position: absolute;
    right: 10px;
    width: 15px;
    height: 15px;
    background: red;
    border-radius: 50%;
}

.cardTitle {
    font-weight: bold;
}

.cardMail {
    flex: 1;
    min-width: 0;
}

.selectedCard {
    background: #a97eb2;
    border-radius: 13px;
    color: white;
}

.overflowEllipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.scrollableBlock {
    width: 100%;
    overflow-y: auto;
    padding: 30px 15px;
    background-repeat: repeat;
    background-size: contain;
}

.childScrollableBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    min-height: 100%;
}

.mail {
    min-width: 60px;
    max-width: 80%;
    position: relative;
    margin-top: 10px;
    padding: 10px 10px 25px;
    background-color: #f5e9ff;
    border-radius: 10px;
}

.time {
    position: absolute;
    right: 10px;
    bottom: 4px;
    color: grey;
}

.indicator {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 1px;
    color: #FFFFFF00;
}

.spinner {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.spinner div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #fff;
    animation: spinner 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.spinner div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
}
.spinner div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
}
.spinner div:nth-child(3) {
    left: 56px;
    animation-delay: 0s;
}
@keyframes spinner {
    0% {
        top: 8px;
        height: 64px;
    }
    50%, 100% {
        top: 24px;
        height: 32px;
    }
}

.messagesByDate {
    margin-top: 10px;
    width: 100%;
}

.date {
    display: flex;
    justify-content: center;
}

.mails {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.dateTitle {
    padding: 2px 10px;
    background: #d08ae8;
    border-radius: 12px;
    color: white;
    font-weight: bold;
}

.link {
    cursor: pointer;
}

.link svg {
    width: 12px;
    fill: blue;
}