.product-entity-edit {
    padding: 40px 0 0 0;
}

.product-entity-edit__input-block {
    margin-bottom: 50px;
}

.product-entity-edit__input > div:first-child {
    width: 185px;
    text-align: left;
}

.product-entity-edit__input > div:last-child {
    flex: 0 0;
}

.product-entity-edit__double-checkbox {
    display: flex;
}

.product-entity-edit__double-checkbox > div {
    flex: 0 0 auto;
    text-align: center;
    margin-right: 30px;
  }