.trip-plan-details {
    position: relative;
    flex: 1 1 auto;
    height: 100%;
}

.trip-plan-details .old-ui-route {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
}

.trip-plan-details__none {
    display: none;
}

.old-ui-route__sidebar {
    flex: 0 1 auto;
    width: 392px;
    margin-right: 16px;
}

.old-ui-route__excel-file-input {
    flex: 0 1 auto;
}

.old-ui-route__car-daily-trips-container {
    flex: 1 1 auto;
    height: 485px;
    overflow-y: scroll;
}

.old-ui-route__car-daily-trips-inner-container {
    min-height: 100px;
    height: 100%;
}

.old-ui-route__progress-container {
    display: flex;
    justify-content: center;
    padding-top: 32px;
}

.old-ui-route__total-coefficient-input-container {
    margin-top: 16px;
}

.old-ui-route__total-coefficient-input {
    width: 100%;
}

.trip-plan-details__trip-list .entity-list__table-cell {
    padding: 8px;
}

.trip-plan-details__route-info-container {
    display: flex;
    flex-direction: column;
}

.old-ui-route__map-container {
    flex: 1 1 auto;
    position: relative;
    border-radius: 4px;
    background-color: #f7f3df;
    overflow: hidden;
}

.old-ui-route__map-container .ant-spin-nested-loading {
    width: 100%;
    height: 100%;
    display: flex;
}

.old-ui-route__map-container .ant-spin-container {
    width: 100%;
    height: 100%;
}

.old-ui-route__map {
    display: none;
    width: 100%;
    height: 100%;
}

.old-ui-route__map_ready {
    display: block;
}

.trip-plan-details__order-details-container {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1000;
}

.trip-plan-details__order-details-close-button {
    position: absolute;
    top: 12px;
    right: 12px;
    padding: 8px;
    cursor: pointer;
    z-index: 10001;
}

.trip-plan-details__button {
    margin-top: 30px;
    min-width: 200px;
    min-height: 40px;
    align-self: flex-end;
}

.trip-plan-details__order-place-picker {
    width: 100%;
    height: 100%;
    background-color: white;
}

.trip-plan-details__trip-list .entity-list__data-row.entity-list__row_warning {
    background-color: rgba(255, 78, 17, .167);
}

.trip-plan-details__button-group {
    margin-bottom: 16px;
}