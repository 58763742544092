.geocode-polyline__marker {
    width: 18px !important;
    height: 18px !important;
    border: 2px solid #0000fe;   
    border-radius: 50%;
    background-color: #fff;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    margin-top: -9px !important;
    margin-left: -9px !important;
}

.geocode-polyline__marker_green {
    border-color: #008001;
}

.geocode-polyline__marker_orange {
    border-color: #ff7f00;
}

.geocode-polyline__marker_red {
    border-color: #fe0000;
}

.geocode-polyline__default-line {
    stroke: #0000fe;
    stroke-width: 2;  
}