.sidebar {
    display: flex;
    position: relative;
    z-index: 1;
}

.sidebar__item {
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
    width: 100%;
    min-width: 260px;
    overflow-x: auto;
}

.sidebar__item:not(:first-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    padding-left: 16px;
}

.sidebar__item:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}
