.item {
    margin-bottom: 2px;
}

.notAvailable {
    opacity: 0.4;
}

.removeInterval {
    cursor: pointer;
    margin-right: 5px;
}

.disableInterval {
    cursor: pointer;
    color: red;
    margin-right: 5px;
}

.enableInterval {
    cursor: pointer;
    color: green;
    margin-right: 5px;
}

.notEditableInterval {
    display: flex;
    align-items: center;
}

.notEditableTimeContainer {
    display: flex;
    align-items: center;
}

.notEditableTime {
    padding: 4px 11px;
}
