.daysOfWeekGroup {
    display: flex;
}

.checkboxWrapper {
    flex: 1 1 0;
    margin-right: 8px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #1890ff;
    border-radius: 4px;
    background-color: #ffffff;
    line-height: 0;
    user-select: none;
}

.checkboxChecked {
    background-color: #1890ff;
}

.checkboxChecked .checkboxLabel {
    color: #ffffff;
}

.checkboxDisabled {
    opacity: 0.3;
}
