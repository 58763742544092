.company-logs__ext-content {
    display: flex;
    border-bottom: 1px solid #e8e8e8;
}

.company-logs__ext-content:last-child {
    border-bottom: none;
}

.company-logs__text {
    flex: 1 1 auto;
}

.company-logs__time {
    flex: 0 1 auto;
    width: 50px;
}

.company-logs__stack-trace {
    flex: 1 1 auto;
}

.company-logs__ext-content:last-child .company-logs__stack-trace {
    margin: 0;;
}

.company-logs__ext-content {
    font-size: 13px;
    margin-bottom: 10px;
}

.company-logs__stack-trace {
    font-size: 11px;
}

.company-logs__method {
    color: #FF4E11;
}

.company-logs__method.GET {
    color: #69B34C;
}

.company-logs__username {
    color: #FF4E11;
}