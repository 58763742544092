.container {
  padding: 5px;
  border-radius: 10px;
  background: #F5EBFF;
  text-align: end;
}

.container p {
  margin: 0;
  padding: 0;
  line-height: 14px;
}