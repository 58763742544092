.wrapper {
    max-width: 100%;
    border: 1px solid #e8e8e8;
}

.wrapper:hover {
    background-color: #e6f7ff;
}

.wrapper:not(:last-child) {
    border-bottom: none;
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
}

.trip {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.tripColor {
    flex: 0 0 3px;
}

.color {
    width: 100%;
    height: 20px;
}

.details {
    flex: 1;
    overflow: hidden;
    padding: 14px 8px;
}

.status {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.driver {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ordersCounter {
    flex: 0 0 100px;
    display: flex;
    flex-direction: column;
    padding: 0 8px;
}

.ordersCounter > div {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.ordersCounter > div > span {
    display: flex;
    align-items: center;
}

.arrow {
    align-self: stretch;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
}

.arrow:hover {
    background: #e8e8e8;
}

.action {
    cursor: pointer;
}