.authenticated-user-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.authenticated-user-info__sider-header {
    margin-bottom: 8px;
    padding: 20px;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.2);
}

.authenticated-user-info__sider-header_collapsed {
    background-color: rgba(255, 255, 255, 0.2);
    line-height: 62px;
    margin-bottom: 62px;
    text-align: center;
}

.authenticated-user-info__username {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 24px;
}

.authenticated-user-info__company {
    font-size: 12px;
    opacity: 0.5;
    line-height: 20px;
}

.authenticated-user-info__logout-container {
    text-align: center;
}

.authenticated-user-info__logout-button {
    padding: 0 10px;
    background: url('../../icons/logout.svg') no-repeat 0% 0%;
}

.authenticated-user-info__logout-button:hover {
    background: url('../../icons/logout_hover.svg') no-repeat;
}

.authenticated-user-info__logout-button:active {
    background: url('../../icons/logout_pressed.svg') no-repeat;
}

.authenticated-user-info__logout-menu{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.authenticated-user-info__user-icon{
    width: 24px;
    height: 24px;
    vertical-align: middle;
}

.authenticated-user-info__logout-link {
    padding: 0;
    background: url('../../icons/logout_small.svg') no-repeat 0% 50%;
    padding-left: 20px;
}

.authenticated-user-info__logout-link:hover {
    background: url('../../icons/logout_small_hover.svg') no-repeat 0% 50%;
    color: #68BDFB;
}

.authenticated-user-info__logout-link:active {
    background: url('../../icons/logout_small_pressed.svg') no-repeat 0% 50%;
    color: #006DD1;
}

.authenticated-user-info__collapsed-username{
    font-weight: 500;
}