.marker {
    font-size: 10px;
    font-weight: 700;
    text-align: center;
    width: 8px !important;
    height: 8px !important;
    margin-top: -4px !important;
    margin-left: -4px !important;
    background-color: #28e135;
    border-radius: 50%;
    border: 1px solid black;
}

.buttonIcon {
    font-size: 17px;
}

.buttonIconActive {
    color: #1890ff;
}