.car-list {
}

.car-list__driver-name {
    font-size: 12px;
}

.car-list__coefficient-text {
    color: rgba(0, 0, 0, 0.4);
}

.car-list .car-loading-info {
    width: 100%;
    padding-top: 4px;
    vertical-align: middle;
}

.car-list__warning-icon {
    margin: -8px -8px -8px 8px;
    padding: 8px;
    cursor: help;
}

.car-list__warning-button {
    width: 24px;
    height: 24px;
    padding: 4px;
    line-height: 0;
    border-color: #FD1E1E;
    background: rgba(253, 30, 30, 0.1);
}

.car-list__warning-button:hover {
    border-color: #FD1E1E;
    background: rgba(253, 30, 30, 0.1);
}

.car-list__warning-button .anticon {
    line-height: 0;
}

.car-list__fake-button {
    width: 24px;
    height: 24px;
}