.wrapper {
    position: relative;
    height: 100%;
}

.container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: stretch;
}

.title {
    text-align: center;
    text-decoration: underline;
}

.table {
    flex: 0 0 400px;
    overflow-y: scroll;
}

.charts {
    flex: 1;
    padding: 0 20px;
    overflow: hidden;
}

.icon {
    width: var(--svg-icon-size-s);
    height: var(--svg-icon-size-s);
}

.row {
    cursor: pointer;
}

.yellow {
    stroke: var(--svg-icon-yellow);
}

.blue {
    stroke: var(--svg-icon-blue);
}

.name {
    white-space: nowrap;
    overflow-y: hidden;
    text-overflow: ellipsis;
}

.notActive {
    opacity: 0.3;
}

.times {
    width: 100%;
    white-space: nowrap;
    overflow-x: auto;
    margin-bottom: 40px;
}

.time {
    display: inline-block;
    padding: 4px 8px;
    border: 1px solid lightgrey;
    border-radius: 10px;
    cursor: pointer;
    margin-right: 10px;
}

.selected {
    background: var(--selected-table-item);
}