.orders-report {
}

.orders-report__bottle-icon {
    margin-right: 8px;
    transform: translateY(2px);
}

.orders-report__warning-text {
    color: #ff302f;
}

.orders-report__warning-icon {
    width: auto;
    height: auto;
}

.orders-report__status-icon-ok {
    width: auto;
    height: auto;
}

.orders-report__status-icon-failed {
    width: auto;
    height: auto;
    transform: translateY(3px);
}

.orders-report__status-icon-unknown {
    transform: translateY(4px);
}

.orders-report__order-details {
    display: flex;
}

.orders-report__order-details-column {
    flex: 1 0 auto;
    max-width: 400px;
}

.orders-report__order-details-column:not(:last-child) {
    margin-right: 32px;
}

.orders-report__order-details-column-header {
    font-weight: 600;
    color: rgb(102, 102, 102);
}

.orders-report__order-details-column-subheader {
    padding: 8px 0 0 6px;
    color: rgb(102, 102, 102);
    font-size: 0.9em;
}

.orders-report__order-details-item {
    padding: 6px;
}

.orders-report__order-details-item svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    color: rgb(104, 189, 251);
    transform: translateY(3px);
}

.orders-report__order-details-item-count {
    float: right;
}

.orders-report__qr-payment {
    margin-left: 32px;
}

.orders-report__qr-payment svg {
    position: relative;
    top: 4px;
    width: 24px;
    height: 24px;
    margin-top: -8px;
    margin-right: 4px;
}

.orders-report__order-details-wallet {
    margin: 0px 7px -5px -3px !important;
    width: 24px !important;
    height: 24px !important;
}

.orders-report__order-details-column .orders-report__warning-icon,
.orders-report__order-details-column .orders-report__calendar-icon {
    float: left;
}

.orders-report__order-details-column .orders-report__calendar-icon {
    margin: 3px;
}

.orders-report__reschedule-datetime {
    width: 100px;
    float: left;
}
