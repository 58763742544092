.wrapper {
    position: relative;
    height: 100%;
}

.container {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.list {
    position: relative;
    flex-basis: 400px;
    overflow-y: scroll;
}

.elem {
    display: flex;
    padding: 8px 20px;
    height: 75px;
    background-color: #f3f3f3;
    border: 1px solid #f3f3f3;
    border-bottom: 1px solid white;
    cursor: pointer;
    align-items: center;
}

.timePicker {
    margin-left: 10px;
}

.map {
    flex: 1;
}

.modalLabels label {
    display: block;
    padding: 10px 0;
}

.buildSelect {
    width: 100%;
}

.modalLabels hr {
    border: none;
    border-bottom: 1px dashed gray;
}

.modalLabels label:last-child {
    border-bottom: none;
}

.loadingCar {
    width: 100px;
    margin-left: 10px
}

.listTop {
    display: flex;
    flex-direction: row;
}

.providers {
    margin-left: auto;
}