.marker {
    font-size: 10px;
    font-weight: 700;
    text-align: center;
    width: 18px !important;
    height: 18px !important;
    margin-top: -9px !important;
    margin-left: -9px !important;
    background-color: #28e135;
    border-radius: 50%;
    border: 1px solid black;
}

.path {
    stroke-width: 3;
}