.chart {
    display: flex;
    flex-direction: column;
}

.chart .chart-title {
    margin: 0 auto;
}

.chart .chart-tooltip {
    background-color: #fff;
    padding: 4px 8px;
    border: 1px solid #00000044;
    border-radius: 4px;
    box-shadow: 2px 2px 2px #00000044;
}