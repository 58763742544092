.order {
    padding: 5px 10px;
    background-color: #f3f3f3;
    border: 1px solid #f3f3f3;
    border-bottom: 1px solid white;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
}

.order hr {
    width: 100%;
    border: none;
    margin: 2px 0;
}

.order:hover {
    border: 1px solid red;
    border-radius: 3px;
}

.clientName {
    color: gray;
    flex: 1 0 0;
}

.period {
    width: 100px;
    text-align: right;
    flex: 0 0 100px;
}

.confirmed {
    background-color: #ccffcc;
}

.selected {
    background-color: #e6f7ff;
}