.trip-plan-data-orders td:last-child {
    width: 150px;
}

.trip-plan-data-orders__desired-time-column {
    white-space: nowrap;
}

.trip-plan-data-orders__order-icons-container {
    display: inline-flex;
    align-items: center;
}

.trip-plan-data-orders__order-operations:not(:last-child) {
    margin-right: 15px;
}

.trip-plan-data-orders__order-warning-icon {
    margin: -8px;
    padding: 8px;
    cursor: help;
}

.trip-plan-data-orders__order-warning-icon svg {
    width: 13px;
    height: 11px;
}

.trip-plan-data-orders .entity-list__search-filters {
    flex: 1;
}