.loading-info {
    display: inline-block;
}

.loading-info__bar,
.loading-info__bar-line {
    border-radius: 9999px;
    height: 3px;
}

.loading-info__bar {
    display: flex;
    margin-top: 1px;
    min-width: 32px;
    background-color: #f1f1f1;
}

.loading-info__bar-line {
    background-color: #21e634;
}

.loading-info__bar-line_error {
    background-color: #ff1818;
}

.loading-info__value {
    white-space: nowrap;
}