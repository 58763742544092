.map__map_container {
    width: 100%;
    height: 100%;
}

.map__marker {
    border-width: 2px;
    border-style: solid;
    border-radius: 9999px;
    background-color: #fff;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    margin-top: -9px !important;
    margin-left: -9px !important;
}

.map__geocode_marker {
    margin-top: -5px !important;
    margin-left: -5px !important;
}

.map__stop_marker {
    border-width: 0px;
    width: 16px !important;
    height: 16px !important;
    margin-top: -8px !important;
    margin-left: -8px !important;
    line-height: 17px;
    background-color: #ffc000;
}

.map__store_marker {
    background-color: #ffffffbb;
}

.map__marker.map__marker_selected {
    background-color: #fffc66;
    box-shadow: 0 0 8px 2px #fff;
}

.map__marker.map__marker_new {
    background-color: #ffd591;
}

.map__marker.map__marker_not-own {
    color: #cf1322;
}

.map__marker.map__marker_confirmed {
    background-color: #fffc66;
    box-shadow: 0 0 8px 2px #fff;
    width: 14px !important;
    height: 14px !important;
    margin-top: -7px !important;
    margin-left: -7px !important;
}

.map__marker-previos_selected {
    width: 14px !important;
    height: 14px !important;
    margin-top: -7px !important;
    margin-left: -7px !important;
}

.map svg {
    width: auto;
    height: auto;
}

.map__marker.map___driver-place-marker {
    width: 10px !important;
    height: 10px !important;
    margin-top: -5px !important;
    margin-left: -5px !important;
    border-width: 1px;
}