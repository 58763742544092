.working-day-item {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
}

.working-day-item__basket-list {
    flex: 0 0 400px;
    overflow-y: scroll;
}

.working-day-item__map-container {
    flex: 1;
}