.wrapper {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.list {
    flex: 0 1 400px;
    height: 100%;
    overflow: scroll;
}

.listItem {
    display: flex;
    padding: 0 10px;
    border: 1px solid lightgrey;
    cursor: pointer;
}

.selectedListItem {
    box-shadow: 0 0 3px 3px yellow inset;
}

.inactive {
    background: #efefef;
}

.listItem:not(:last-child) {
    border-bottom: none;
}

.listItemContent {
    flex: 1 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
}

.map {
    flex: 1;
    height: 100%;
}

.marker {
    border-width: 3px 1px;
    border-style: solid;
    border-color: rgb(0, 72, 206);
    width: 20px !important;
    height: 20px !important;
    margin-left: -10px !important;
    margin-top: -10px !important;
    background: linear-gradient(to top, #d4d4d48a, #ffffffaa);
    border-radius: 10px;
}

.driverPlaceMarker {
    border-width: 3px 1px;
    border-style: solid;
    background: #509252aa;
    width: 14px !important;
    height: 14px !important;
    margin-left: -7px !important;
    margin-top: -7px !important;
    border-radius: 10px;
    transition: width 0.8s ease, height 0.8s ease, margin-left 0.8s ease, margin-top 0.8s ease, border 0.4s ease;
}

.suspectedDriverPlaceMarker {
    background: rgba(255, 0, 0, 0.67);
}

.selectedDriverPlace {
    background-color: #fffc66;
    box-shadow: 0 0 8px 2px #fff;
}

.modal {
    position: relative;
    width: 352px;
    padding: 16px 16px 24px;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    overflow-y: auto;
    color: rgba(0, 0, 0, 0.8);
}

.underline {
    text-decoration: underline;
}

.comment {
    margin-top: 15px;
}

.close {
    position: absolute;
    top: 6px;
    right: 6px;
    padding: 8px;
    cursor: pointer;
    z-index: 10001;
}

.hiddenText {
    font-size: 10px;
    color: #fff;
}