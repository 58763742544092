.page {
    display: flex;
    flex-direction: column;
}

h1.page__title {
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 0 32px;
}

.page__content {
    flex: 1 1 auto;
}

.page__content .routed-tabs.fullheight {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.page__content .routed-tabs .ant-tabs ~ div {
    flex: 1 1 auto;
}

.page__content-wrapper {
    position: relative;
    height: 100%;
    padding: 0 32px 16px;
}

.page__content-wrapper > .ant-spin-nested-loading {
    display: flex;
    flex-direction: column;
}

.page__content-wrapper > .ant-spin-nested-loading > * {
    flex: 1 0 auto;
}

.page__content-wrapper > .ant-spin-spinning {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
