.map-container {
    position: relative;
    height: 100%;
    width: 100%;
}

.map-container__leaflet-map {
    height: 100%;
    width: 100%;
}

.leaflet-map-content__top-left {
    position: absolute;
    top: 11px;
    left: 55px;
    max-height: calc(100% - 22px);
    pointer-events: none;
}

.leaflet-map-content__top-left * {
    pointer-events: auto;
}

.leaflet-map-content__top-right {
    position: absolute;
    top: 11px;
    right: 55px;
    max-height: calc(100% - 22px);
    pointer-events: none;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.leaflet-map-content__top-right * {
    pointer-events: auto;
}

.leaflet-map-content__bottom-left {
    position: absolute;
    bottom: 11px;
    left: 55px;
    max-height: calc(100% - 22px);
    pointer-events: none;
}

.leaflet-map-content__bottom-left * {
    pointer-events: auto;
}

.leaflet-map-content__bottom-right {
    position: absolute;
    bottom: 11px;
    right: 55px;
    max-height: calc(100% - 22px);
    pointer-events: none;
}

.leaflet-map-content__bottom-right * {
    pointer-events: auto;
}

.leaflet-app-control:empty {
    display: none;
}