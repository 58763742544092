.plan-list-route__buttons-group {
    display: flex;
    margin-bottom: 15px;
}

.plan-list-route__buttons-group>button {
    margin-right: 15px;
}

.plan-list-route__text_margin {
    margin-right: 20px;
}

.plan-list-route__plans-group-button {
    cursor: pointer;
}

.plan-list-route__data {
    margin-top: 15px;
}

.plan-list-route__plans-group-header {
    display: flex;
    width: 100%;
    background: #E8E8E8;
    min-height: 40px;
}

.plan-list-route__plans-group-header > div {
    flex: 1 1;
    padding-left: 15px;
    line-height: 40px;
}

.plan-list-route__plans-group-header > div:first-child {
    flex: 0 0 200px;
}

.plan-list-route__plans-group-header > div:last-child {
    flex: 0 0 100px;
}

.plan-list-route__plans-group {
    display: flex;
    width: 100%;
    border: 1px solid #E8E8E8;
}

.plan-list-route__plans-group:not(:last-child) {
    border-bottom: none;
}

.plan-list-route__plans-group-date {
    display: flex;
    align-items: center;
    flex: 0 0 200px;
    border-right: 1px solid #E8E8E8;
    padding-left: 15px;
}

.plan-list-route__plans {
    flex: 1;
}

.plan-list-route__plan {
    display: flex;
    width: 100%;
    min-height: 40px;
    cursor: pointer;
}

.plan-list-route__plan > div {
    flex: 1 1;
    padding-left: 15px;
    line-height: 40px;
}

.plan-list-route__plan > div:last-child {
    flex: 0 0 100px;
}

.plan-list-route__plan:not(:last-child) {
    border-bottom: 1px solid #E8E8E8;
}