.store-map-marker {
    border: 1px solid grey;
    border-width: 2px;
    width: 18px !important;
    height: 18px !important;
    margin-left: -9px !important;
    margin-top: -9px !important;
    background: #ffffff85;
    border-radius: 50%;
    font-size: 10px;
    font-weight: 700;
    text-align: center;
}

.store-map-marker_main {
    background: #FFD70055;
}